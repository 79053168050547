<template>
  <div class="forum">
    <div class="top">
      <h1>论坛</h1>
      <span>Forum</span>
      <div class="image">
        <img src="../../assets/index/index (15).png" alt />
        <img src="../../assets/index/index (16).png" alt />
      </div>
    </div>
    <div class="forum-content" v-loading="forumLoading">
      <el-row :gutter="0">
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <el-carousel trigger="click" height="380px">
            <el-carousel-item
              v-for="(item, index) in forumCarousel"
              :key="index"
             
            >
               <el-image
                style="width: 100%; height: 100%"
                :src="item.img_url"
                 @click="$handleRoute(item.target_url)"
                
              ></el-image>
             
            </el-carousel-item>
          </el-carousel>
         
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
          <div class="fr">
            <div
              class="forum-item"
              v-for="(item, index) in forumList"
              :key="index"
            >
              <div style="cursor: pointer" @click="clickForum(item)">
                <div class="item-top">
                  <div class="title">{{ item.title }}</div>
                  <div class="time">{{ item._create_time }}</div>
                </div>
                <div class="con">
                  {{ item.recommend_str }}
                </div>
              </div>

              <div class="btn">
                <div class="like" @click="clickLike(item.id, index)">
                  <img
                    src="../../assets/index/index (24).png"
                    v-if="!item.is_collect"
                    alt
                  />
                  <img
                    src="../../assets/images/me/ysc.png"
                    v-if="item.is_collect"
                    alt
                  />

                  <!-- <i
                    class="el-icon-star-on"
                    style="font-size: 24px; color: #409eff"
                    v-if="item.is_collect"
                  ></i>
                  <i
                    class="el-icon-star-off"
                    style="font-size: 24px; "
                    v-if="!item.is_collect"
                  ></i> -->

                  <span :class="{ collectbgc: item.is_collect }">
                    {{ item.collect_num }}</span
                  >
                </div>
                <div class="star" @click.stop="clickStar(item.id, index)">
                  <img
                    src="../../assets/index/index (17).png"
                    v-if="!item.is_praise"
                    alt
                  />
                  <img
                    src="../../assets/images/me/ydz.png"
                    v-if="item.is_praise"
                    alt
                  />

                  <span :class="{ praisebgc: item.is_praise }">
                    {{ item.praise_num }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { forum_list, collect, praise } from "@/api/me.js";

export default {
  props: {
    forumCarousel: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      forumList: [],
      // collect: "",
      forumLoading: false,
    };
  },
  components: {},
  created() {
    this.getForumList();
  },
  methods: {
    getForumList() {
      this.forumLoading = true;
      forum_list({ page: 1, page_size: 3, type: -2 ,recommend:-1})
        .then((res) => {
          this.forumList = (res.data || {}).data;
          // console.log(this.forumList, "论坛成功");
          this.forumLoading = false;
        })
        .catch((err) => {
          // console.log(err, "论坛失败");
          this.forumLoading = false;
        });
    },
    // 点击论坛
    clickForum(item) {
      this.$router.push({ path: "/forum/forumSee", query: { id: item.id } });
    },
    // 收藏
    clickLike(id, index) {
      collect({ id: id })
        .then((res) => {
          this.forumList[index].collect_num = res.data.collect_count;
          this.forumList[index].is_collect = res.data.is_collect;
          // this.collect = res.data.is_collect;
          this.$message.success(res.msg);
        })
        .catch((err) => {});
    },
    // 点赞
    clickStar(id, index) {
      console.log(id);
      praise({ id: id })
        .then((res) => {
          console.log(res);

          this.forumList[index].praise_num = res.data.praise_count;
          this.forumList[index].is_praise = res.data.is_praise;
          // this.collect = res.data.is_collect;
          this.$message.success(res.msg);
        })
        .catch((err) => {});
    },

    handleClick() {},
  },
};
</script>

<style lang="less" scoped>
.praisebgc {
  color: #ff623f;
}
.collectbgc {
  color: #fac83e;
}
.forum-item {
  padding-top: 14px;
  width: 100%;
  height: 126px;
  box-sizing: border-box;
  background: #ffffff;
  border-bottom: 1px solid #979797;
  .item-top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #2e2e2e;
    .title {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2e2e2e;
      overflow : hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;

    }
    .time{
      width: 100px;
      white-space: nowrap;
      text-align: right;
    }
  }
  .con {
    // width: 463px;
    margin: 7px 0 12px;
    color: #2e2e2e;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .btn {
    display: flex;
    img {
      width: 20 px;
      height: 20px;
    }
    .star,
    .like {
      font-size: 14px;
      color: #666;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .like {
      margin-right: 3%;
    }
  }
}
.forum {
  height: auto;
  padding-top: 50px;

  .top {
    text-align: center;
    h1 {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2e2e2e;
      margin: 0;
    }
    span {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #00957e;
    }
    .image {
      margin-top: 5px;
      margin-bottom: 40px;
      img {
        width: 55px;
        height: 55px;
      }
    }
  }
  .forum-content {
    // display: flex;
    // justify-content: space-between;

    .fr {
      // flex: 1;
      box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
      border: 1px solid #979797;
      padding: 0 32px;
      box-sizing: border-box;
      height: 100%;
      overflow: scroll;
      overflow-y: auto;
      height: 380px;
    }
  }
  .forum-item {
    &:nth-child(3) {
      border: none;
    }
  }
  ::v-deep{
    .el-carousel{
      border-radius: 30px 0  0 30px ;
    }
    }
}
</style>
