<template>
  <div class="box">
    <div class="problem-item">
      <h1 :style="{'color':proItem.color}">{{proItem.title}}</h1>
      <span v-if="!proItem.list.length">暂无数据</span>
      <div class="list">
        <div class="item-content" v-for="item,index in proItem.list" :key="index" @click="clickProblem(item)">
        <span>0{{index+1}}</span>
        <!-- <span class="title">{{item.title}}</span> -->
        <el-tooltip effect="dark" :content="item.title" placement="top" v-if="item.title.length > 7">
             <!-- <span class="title">{{item.title}}</span> -->
             <!-- <template slot="content"> -->
              <span class="title" v-html="item.title"></span>
            <!-- </template> -->
        </el-tooltip>
        <span  v-else v-html="item.title"></span>
        <el-button :style="{'backgroundColor':proItem.color,'color':'#fff'}">{{item.from_title}}</el-button>
      </div>
      </div>
      
      <img :src="proItem.src" alt class="go" @click="proItemGo" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    proItem: Object,

  },
  data () {
    return {}
  },
  components: {},
  methods: {
    proItemGo () {
      this.$store.state.headerValue = {
        fields: 'from_id',
        flag: true,
        value: {
          label: this.proItem.title,
          value: this.proItem.value
        },
      }
      this.$router.push({ path: "/problemList" })
    },
    clickProblem (item) {
      if (!localStorage.getItem("user_token")) {
        return this.$store.state.lodinShow = true
      }
       if(this.userType==0){
         this.$store.state.showBinding=true
        return
      }
      console.log(item)
      this.$router.push({ path: '/problemList/student/problemListDetails', query: { id: item.id } })
    },
  },
}
</script>

<style scoped lang="less">
.box {
  height: 350px;
}
.problem-item {
  // width: 400px;
  height: 295px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 100px 10px 10px 10px;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border: 1px solid #b3b3b3;
  text-align: center;
  position: relative;
  h1 {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #1c61c4;
    margin: 16px 0 10px;
  }
  .list{
    overflow-y: auto;
    height: 200px;
  }
  .item-content {
    // margin: 0 46px 0 37px;
    padding: 0 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 44px;
    cursor: pointer;
    // margin-bottom: 24px;
    .title {
      width: 132px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    span {
      color: #353535;
    }
    .el-button {
      padding: 0;
      text-align: center;
      width: 93px;
      height: 23px;
      background: #2d93f8;
      border: none;
      border-radius: 2px;
    }
  }
  .go {
    cursor: pointer;
    width: 90px;
    height: 90px;
    position: absolute;
    right: -40px;
    bottom: -45px;
    z-index: 2;
  }
}
</style>
