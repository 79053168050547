<template>
  <div class="main context">
    <div class="top">
      <h1>比赛·考试·作业</h1>
      <span>Contest · Exam · Homework</span>
      <div class="image">
        <img src="../../assets/index/index (6).png" alt />
        <img src="../../assets/index/index (7).png" alt />
      </div>
    </div>
    <div class="context-content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="比赛" name="match"> </el-tab-pane>
        <el-tab-pane label="考试" name="examination"> </el-tab-pane>
        <el-tab-pane label="作业" name="task"> </el-tab-pane>
      </el-tabs>
      <el-carousel trigger="click" height="170px">
        <el-carousel-item v-for="(item, index) in matchCarousel" :key="index">
          <el-image style="width: 100%; height: 100%" :src="item.img_url" @click="clickImg(item)"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="bottom">
        <div class="img_box">
          <div class="img">
            <img :src="img.img_url" alt />
          </div>
        </div>

        <div class="table" v-loading="tableLoading">
          <el-table :data="matchList" @row-click="rowClick" height="386px">
            <el-table-column property="date" align="center" label="状态" width="80" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="state" style="color: #28b58c" v-if="scope.row.activ_status == 1">
                  正在进行
                </div>
                <div class="state" style="color: #f15153" v-if="scope.row.activ_status == -2">
                  已结束
                </div>
                <div class="state" style="color: #909399" v-if="scope.row.activ_status == -1">
                  未开始
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="title" label="题单标题" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" label="类型" width="160" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="type">
                  <div class="type_left">
                    <div :style="{ background: scope.row.type_color?scope.row.type_color:'#00957e' }">
                      {{ scope.row.type_str }}
                    </div>
                  </div>
                  <div class="type_right">
                    <div :style="{ background: scope.row.match_color?scope.row.match_color:'#00957e' }" v-if="scope.row.match_title">
                      {{ scope.row.match_title }}
                    </div>
                    <div style="color: #000" v-if="!scope.row.match_title">
                      --
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="question_num" align="center" label="题数" width="80" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="topicNum">{{ scope.row.question_num }}题</div>
              </template>
            </el-table-column>
            <el-table-column prop="activ_time" align="center" label="时间" show-overflow-tooltip>
              <template slot-scope="scope">
                <div>
                  <div>
                    {{ scope.row.activ_time ? scope.row.activ_time : "--" }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="is_open_str" align="center" label="权限" width="60" show-overflow-tooltip>
            </el-table-column>
            <el-table-column property="group_name" align="center" label="举办者" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-tooltip effect="dark" :content="scope.row.group_name" placement="top" v-if="scope.row.group_name.length > 7">
                  <span>{{ scope.row.group_name.substring(0, 7) + "..." }}</span>
                </el-tooltip>
                <span v-else>{{ scope.row.group_name }}</span>
              </template>

            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { carousel } from "@/api/common.js"
import {is_cat } from "@/api/match.js"
export default {
  name: "course",
  props: {
    tableLoading: {
      type: Boolean,
      default: () => false,
    },
    matchList: {
      type: Array,
      default: () => [],
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    matchCarousel: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      search: "",
      activeName: "match",
      i: 0,
      imgId: 11,
      img: {},
    }
  },
  created () {
    // this.activeName = this.optionList[0].label;
    this.getTitle()
    this.getImg()
  },
  components: {},
  methods: {
    // 点击轮播
    clickImg (url) {
      console.log(url)
      if(url.target_url){
         window.open(url.target_url)
      }
     
    },
    getTitle () {
      // console.log(this.optionList, "2222222222")
    },
    getImg () {
      carousel({ type: this.imgId })
        .then(({ data }) => {
          this.img = data.list[0]
          // console.log(data, "比赛图片")
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClick (e) {
      this.i = e.index
      // let id=this.optionList[parseInt(e.index)].id
      console.log(parseInt(e.index))
      console.log(this.optionList)
      let i = parseInt(e.index)
      let id = this.optionList[i].value
      this.$parent.getMatchList(id)
      console.log(e.index)
      console.log(this.i)
      if (this.i == 0) {
        this.imgId = 11
      } else if (this.i == 1) {
        this.imgId = 12
      } else if (this.i == 2) {
        this.imgId = 13
      }
      console.log(this.imgId)
      this.getImg()
    },
    rowClick (e) {
      console.log(e,'e')
      if (!localStorage.getItem("user_token")) {
        return this.$store.state.lodinShow = true
      }
      if (!this.userType) {
        this.$store.state.showBinding = true
        return
      }
      is_cat({ id: e.id }).then(res => {
        this.$router.push({ name: "matchDetail"+e.type, query: { id: e.id} })
      })
     
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-table .el-table__row td {
    padding: 17px 0 !important;
  }
  .el-table__row {
    cursor: pointer;
  }
}

.type {
  display: flex;
  // justify-content: space-around;
  .type_left {
    div {
      width: 49px;
      height: 23px;
      line-height: 23px;

      border-radius: 2px;
      // padding: 0 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin-right: .8rem;
    }
  }
  .type_right {
    min-width: 73px;
    height: 23px;
    // background: #ffdf3e;
    border-radius: 2px;
    div {
      height: 23px;
      line-height: 23px;
      border-radius: 2px;
      padding: 0 6px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.context {
  height: auto;
  padding-top: 50px;

  .top {
    text-align: center;
    h1 {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2e2e2e;
      margin: 0;
    }
    span {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #00957e;
    }
    .image {
      margin-top: 5px;
      margin-bottom: 40px;
      img {
        width: 55px;
        height: 55px;
      }
    }
  }
  .context-content {
    ::v-deep {
      .el-tabs__header {
        .el-tabs__nav-wrap::after {
          background-color: #fff;
        }
        .el-tabs__nav-wrap {
          display: flex;
          justify-content: center;
        }
        width: 50%;
        margin: 0 auto 15px;
      }
      .el-tabs__item {
        font-size: 18px;
        font-weight: 400;
        color: #00957e;
      }
      .el-tabs__active-bar {
        background-color: #00957e;
      }
    }
    .top {
      width: 100%;
      height: 170px;
    }
    .bottom {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .img_box {
        width: 30%;
        border-radius: 20px 0 0 20px;
      }

      .img {
        width: 100%;
        border-radius: 20px 0 0 20px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .table {
      width: 70%;
      border: 1px solid #979797;
      ::v-deep {
        .el-table thead {
          color: #000;
        }
      }
      /deep/.el-table {
        height: 100%;
        .el-table__header {
          height: 60px !important;
          width: 100% !important;
          tr {
            border-bottom: 1px solid #ebeef5;
          }
          th {
            border: 0;
            padding: 5px 0;
          }
        }

        .el-table__row {
          td {
            padding: 19px 0;
          }
          &:last-child {
            td {
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>
