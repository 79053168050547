<!--  -->
<template>
  <div class="">
    <el-table
      :data="testData"
      tooltip-effect="dark"
      style="width: 100%"
      :row-style="{ height: '0' }"
      :cell-style="{ padding: '5px', cursor: 'pointer' }"
      :header-cell-style="{
        height: '50',
        padding: '0',
        background: '#F9F9F9',
      }"
    >
      <el-table-column
        label="名称"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span @click="goTest(scope.row.list_id1)">{{
            scope.row.title1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="题解" width="80" align="center">
        <template slot-scope="scope">
          <div @click.stop="checkBtn(scope.row.course_id1)">
            <el-button type="text" size="small" style="font-size: 14px">链接</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="名称"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope"
          ><span @click="goTest(scope.row.list_id2)">{{
            scope.row.title2
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="题解" width="80" align="center">
        <template slot-scope="scope" v-if="scope.row.title2">
          <div @click.stop="checkBtn(scope.row.course_id2)">
            <el-button type="text" size="small" style="font-size: 14px">链接</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="名称"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope"
          ><span @click="goTest(scope.row.list_id3)">{{
            scope.row.title3
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="题解" width="80" align="center">
        <template slot-scope="scope" v-if="scope.row.title3">
          <div @click.stop="checkBtn(scope.row.course_id3)">
            <el-button type="text" size="small" style="font-size: 14px">链接</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    testData: {
      type: Array,
      default: () => [],
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //方法集合
  methods: {
    // 跳转题单
    goTest(listId) {
      console.log(listId, "题单条状详情");
      this.$router.push({
        path: "/problemList/student/problemListDetails",
        query: { id: listId },
      });
    },
    // 跳转链接
    checkBtn(courseId) {
      console.log(courseId, "课程链接id");
      this.$handleRoute({ course_id: courseId }, 'courseDetail')
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
</style>