<template>
  <!-- <div class="course-item" v-href="{query:{course_id:item.id},name:'courseDetail'}"
       @click="$handleRoute({course_id:item.id},item.is_plan+'' === '1' ? 'teachPlanCourseDetail': 'courseDetail')"> -->
  <div
    class="course-item"
    @click="$handleRoute({ course_id: item.id }, 'courseDetail')"
  >
    <div class="box">
      <!-- <div class="text">
        {{ item.description }}
      </div> -->
      <div style="width: 100%; height: 8.5rem; position: relative">
        <img
          style="width: 100%; height: 100%; border-radius: 8px 8px 0 0"
          :src="item.cover"
          alt=""
        />
        <div class="tags" style="position: absolute; top: -1px; left: -1px">
          <div class="tag pay"  v-if="item.price > 0">付费</div>
          <div class="tag free" v-else>免费</div>
        </div>
      </div>
      <div style="padding: 12px 15px; box-sizing: border-box">
        <div class="title">
          <!-- <span style="font-size: 16px;color: #646464;">{{ item.is_plan === 1 ? '[教案] ':'' }}</span>-->
          {{ item.course_name }}
        </div>
        <div class="time" style="height: 48px;line-height: 48px;">
          {{ item._create_time }} | {{ item.class_hour }}个课时
        </div>
        <div class="collect">
          <span>{{ item.collect_num }}人收藏</span>
          <span>{{ item.buy_num }}人学习</span>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="fl">
        <!-- <img :src="item.teach_headimg" alt /> -->
        <div style="
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 6px;
          ">
        <el-image
          style="
            width: 100%;
            height: 100%;
            border-radius: 50%;
          "
          :src="item.teach_headimg"
          fit="cover"
        ></el-image>
        </div>
        <!-- <el-avatar :size="size" :src="item.teach_headimg"></el-avatar> -->
        <div class="teaName">{{ item.teach_name }}</div>
      </div>
      <div class="fr" style="color: #F1272B;">
        <span>{{ item.price > 0 ? "￥" + item.price : "" }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang="less">
.course-item {
  cursor: pointer;
  border-radius: 8px;
  height: 293px;
  background: #ffffff;
  box-shadow: 0rem 0.13rem 0.88rem 0rem rgba(0, 0, 0, 0.06);
  border: 0.03rem solid #c3c3c3;
  position: relative;
  box-sizing: border-box;
  .box {
    // padding: 0.8rem 1.13rem;
    .title,
    .time {
      display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
      -webkit-box-orient: vertical; /*值必须为vertical*/
      -webkit-line-clamp: 1; /*值为数字，表示一共显示几行*/
      overflow: hidden;
    }
    .tags {
      width: 46px;
      height: 22px;
      .tag {
      border-radius: 8px 0px 8px 0px;

        width: 46px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-size: 12px;
        color: #ffffff;
      }
      .free{
        background: #e98000;
      }
      .pay{
        background-color: #F64646;
      }
    }
    .title {
      font-size: 1rem;
      font-weight: 600;
      color: #050505;
      line-height: 1.56rem;
    }
    .time {
      font-size: 0.88rem;
      font-weight: 400;
      color: #646464;
    }
    .collect {
      height: 20px;
      line-height: 20px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #9199a1;
      span {
        margin-right: 32px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .text {
      margin: 0.2rem 0;
      font-size: 1rem;
      font-weight: 400;
      color: #646464;
      line-height: 1.4rem;
      // height: 8.15rem;
      // height: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .bottom {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    height: 48px;
    line-height: 48px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    .fl {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #646464;
      display: flex;
      align-items: center;
      .teaName{
        width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      
      // img {
      //   width: 30px;
      //   height: 30px;
      //   margin-right: 5px;
      //   border-radius: 50%;
      //   overflow: hidden;
      // }
    }
    // .fr {
    //   color: #f15153;
    //   font-weight: 600;
    //   span {
    //     font-size: 0.9rem;
    //   }
    // }
  }
}
</style>
