<!--  -->
<template>
  <div class=''>
    <el-carousel trigger="click" height="550px" :autoplay="false">
      <el-carousel-item v-for="(item, index) in indexCarousel" :key="index">
        <el-image style="width: 100%; height: 100%" :src="item.img_url" @click="$handleRoute(item , null , '_banlk')"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { carousel } from "@/api/common.js"
export default {
  props:{
    type:{
      type:Number,
      default:()=>{return 2}
    }
  },
  data () {
    //这里存放数据
    return {
      indexCarousel: [],
    }
  },
  created () {
    this.getIndexCarousel()
  },
  //方法集合
  methods: {
    getIndexCarousel () {
      carousel({ type: this.type })
      .then(({ data }) => {
        this.indexCarousel = data.list
      })
    },

  },
}
</script>
<style  lang='less' scoped>
</style>