<template>
  <el-carousel :trigger="trigger" :height="!isNaN(height) ?height+'px':height" :style="'overflow: hidden;'+(fullWidth ? 'width: 100vw;left: calc(50% - 50vw);':'')" :autoplay="autoplay">
    <el-carousel-item v-for="(item, index) in banners" :key="index" class="carousel-item">
      <slot v-bind:item="item" v-bind:index="index">
        <el-image style="width: 100%; height: 100%" :src="item.img_url" @click="$handleRoute(item.target_url)"></el-image>
      </slot>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "comCarousel",
  props:{
    banners:{
      type:Array,
      default:()=>{return [];}
    },
    height:{
      type:[Number,String],
      default:()=>{return '550px';}
    },
    trigger:{
      type:String,
      default:()=>{return 'click';}
    },
    autoplay:{
      type:Boolean,
      default:()=>{return true;}
    },
    fullWidth:{
      type:Boolean,
      default:()=>{return true;}
    },
  }
}
</script>

<style scoped>

</style>