<template>
  <div class="index">
    <div class="content" style="margin-top: 20px">
      <!-- 课程 -->
      <div class="sysMessage">
        <div style="display: flex; align-items: center; width: 50%">
          <i
            class="el-icon-s-comment"
            style="color: #00957E; font-size: 20px"
          ></i>
          <div class="title">系统消息</div>
          <div style="flex: 1">
            <div
              class="noticItem"
              v-for="(v, i) in listData"
              :key="i"
              :style="{ marginBottom: listData.length > 1 ? '6px' : '0' }"
              @click="goDetails(v.id)"
            >
              <div>
                {{ v.title }}
              </div>
              <div>
                {{ v._create_time ? v._create_time : "-" }}
              </div>
            </div>

            <!-- <vue-seamless-scroll
              :data="listData"
              :class-option="optionHover"
              class="seamless-warp"
              style="font-size: 12px; text-align: center"
            >
              <div class="item">
                <div
                  class="items"
                  v-for="(item, index) in listData"
                  :key="index"
                >
                  <span class="contenttitle" v-text="item.content"></span>
                </div>
              </div>
            </vue-seamless-scroll> -->
          </div>
        </div>
      </div>
      <!-- 课程 -->
      <div class="couresList" style="margin-top: 20px; display: flex">
        <div class="image">
          <img src="@/assets/index/course.png" alt />
        </div>
        <div
          v-loading="courseLoading"
          class="el-table"
          style="background-color: #ffffff; margin-left: 10px"
        >
          <courseList :courseData="courseList"></courseList>
        </div>
      </div>
      <!-- 作业 -->
      <div class="couresList" style="margin-top: 30px; display: flex">
        <div class="image">
          <img src="@/assets/index/work.png" alt />
        </div>
        <div
          class="el-table"
          v-loading="workLoading"
          style="background-color: #ffffff; margin-left: 10px"
        >
          <workList :workData="matchList"></workList>
        </div>
      </div>
      <!-- 试题 -->
      <div class="couresList" style="margin-top: 30px; display: flex">
        <div class="image">
          <img src="@/assets/index/test.png" alt />
        </div>
        <div
          class="el-table"
          style="background-color: #ffffff; margin-left: 10px"
        >
          <testList :testData="testList"></testList>
        </div>
      </div>

      <!-- 比赛考试 -->
      <div class="couresList" style="margin-top: 30px; display: flex">
        <div class="image">
          <img src="@/assets/index/match.png" alt />
        </div>
        <div
          v-loading="tableLoading"
          class="el-table"
          style="background-color: #ffffff; margin-left: 10px"
        >
          <ExamList :workData="ExamList"></ExamList>
        </div>
      </div>

      <!-- 题单 -->
      <!-- <div class="ProblemList" id="contentBox1">
        <div class="top">
          <h1>题单</h1>
          <span>Problem List</span>
          <div class="image">
            <img src="@/assets/index/index (27).png" alt />
            <img src="@/assets/index/index (28).png" alt />
          </div>
        </div>
        <el-row :gutter="80" v-loading="problemLoading">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="8"
            v-for="(item, index) in problemListData"
            :key="index"
            class="Prob-item"
          >
            <ProblemItem :proItem="item"></ProblemItem>
          </el-col>
        </el-row>
      </div> -->
      <!-- 题目 -->
      <!-- <div class="Problem" id="contentBox2">
        <div class="top">
          <h1>题目</h1>
          <span>Problem</span>
          <div class="image">
            <img src="@/assets/index/index (29).png" alt />
            <img src="@/assets/index/index (28).png" alt />
          </div>
        </div>
        <el-row :gutter="100" v-loading="subjectLoading">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="item">
              <div class="t">编程</div>
              <p
                v-if="!optionsList[0].list.length"
                class="noData"
                style="text-align: center; margin-top: 10px"
              >
                暂无数据
              </p>
              <div
                class="con"
                v-for="(item, index) in optionsList[0].list"
                :key="index"
                @click="clickOption(item)"
              >
                <span>0{{ index + 1 }}</span>
                <el-tooltip
                  effect="dark"
                  :content="item.title"
                  placement="top"
                  v-if="item.title.length > 13"
                >
                  <span v-html="item.title"></span>
                  <template slot="content">
                    <span class="ProblemList-name" v-html="item.title"></span>
                  </template>
                </el-tooltip>
                <span
                  class="ProblemList-name"
                  v-else
                  v-html="item.title"
                ></span>
                <el-button
                  :style="{ background: item.diff_color, color: '#fff' }"
                  v-if="item.diff_title"
                  >{{ item.diff_title }}</el-button
                >
                <p v-else style="width: 50px; text-align: center">--</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="item">
              <div
                class="t"
                style="
                  background: linear-gradient(360deg, #16787e 0%, #099685 100%);
                "
              >
                非编程
              </div>
              <p
                v-if="!optionsList[1].list.length"
                style="text-align: center; margin-top: 10px"
                class="noData"
              >
                暂无数据
              </p>

              <div
                class="con"
                v-for="(item, index) in optionsList[1].list"
                :key="index"
                @click="clickOption(item)"
              >
                <span>0{{ index + 1 }}</span>
                <el-tooltip
                  effect="dark"
                  :content="item.title"
                  placement="top"
                  v-if="item.title.length > 13"
                >
                  <span v-html="item.title"></span>
                  <template slot="content">
                    <span class="title" v-html="item.title"></span>
                  </template>
                </el-tooltip>
                <span class="title" v-else v-html="item.title"></span>
                <el-button
                  :style="{ background: item.diff_color, color: '#fff' }"
                  v-if="item.diff_title"
                  >{{ item.diff_title }}</el-button
                >
                <p v-else style="width: 50px; text-align: center">--</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div> -->
      <!-- 比赛·考试·作业 -->
      <!-- <div class="context" id="contentBox3">
        <Context
          :matchList="matchList"
          :optionList="matchOptionList"
          :tableLoading="tableLoading"
          :matchCarousel="matchCarousel"
        ></Context>
      </div>
      <Team id="contentBox4" />
      <Forum id="contentBox5" :forumCarousel="forumCarousel" /> -->
    </div>
    <!-- 导航 -->
    <!-- <div
      class="position"
      :style="
        'position:absolute;z-index:2001;padding-left: 14px;' +
        (!switchPosition ? 'width:10px;overflow-x: hidden;' : '')
      "
    >
      <div class="switch">
        <i
          class="el-icon-caret-right"
          v-if="switchPosition"
          @click="switchPosition = false"
        ></i>
        <i class="el-icon-caret-left" v-else @click="switchPosition = true"></i>
      </div>
      <div
        class="box"
        style="padding: 20px 10px; border-radius: 20px; background: #fff"
      >
        <div class="ul">
          <div
            v-for="(item, index) in positionList"
            :key="index"
            :class="{ indexBgc: indexi == index, li: true }"
          >
            <a @click="clickPosition(index)">{{ item }}</a>
          </div>
        </div>
        <div class="backTop" v-if="scroll > 300">
          <i class="el-icon-caret-top" style="color: #00957e"></i>

          <span @click="backTop"> 顶部</span>
        </div>
      </div>
    </div> -->

    <el-dialog
      width="500px"
      :visible.sync="apply_visible"
      custom-class="ele-dialog-form"
      title="报名"
      @closed="apply_closed"
      @open="open_sign_info"
    >
      <el-form
        ref="sign_form"
        label-width="110px"
        :model="sign_form"
        :rules="sign_rules"
      >
        <el-form-item label="真实姓名：" prop="realname">
          <el-input
            v-model="sign_form.realname"
            placeholder="请输入真实姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学校名称：" prop="school_name">
          <el-input
            v-model="sign_form.school_name"
            placeholder="请输入学校名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="教育ID：" prop="educate_id">
          <el-input
            v-model="sign_form.educate_id"
            placeholder="请输入教育ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="年级：" prop="grade_name">
          <el-input
            v-model="sign_form.grade_name"
            placeholder="请输入年级"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="班级：" prop="class_name">
          <el-input
            v-model="sign_form.class_name"
            placeholder="请输入班级"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="id_card">
          <el-input
            v-model="sign_form.id_card"
            placeholder="请输入身份证号"
            clearable
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            v-model.number="sign_form.mobile"
            placeholder="请输入手机号"
            maxlength="11"
            type="number"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱号：" prop="email">
          <el-input
            v-model="sign_form.email"
            placeholder="请输入邮箱号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="apply_visible = false">取消</el-button>
        <el-button type="info" :loading="loading" @click="cancel" v-if="is_edit"
          >取消报名
        </el-button>
        <el-button type="primary" :loading="loading" @click="sign_save"
          >确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 查看名单 -->
    <el-dialog
      width="900px"
      :visible.sync="rollCall_visible"
      custom-class="ele-dialog-form"
      title="名单列表"
      @open="open_sign_list"
    >
      <el-table :data="rollCall_list" style="width: 100%" height="400">
        <el-table-column prop="realname" label="真实姓名" align="center">
        </el-table-column>
        <el-table-column prop="school_name" label="学校" align="center">
        </el-table-column>
        <el-table-column prop="grade_name" label="年级" align="center">
        </el-table-column>
        <el-table-column prop="class_name" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="educate_id" label="教育Id" align="center">
        </el-table-column>
        <el-table-column prop="id_card" label="身份证号" align="center">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :page-size="10"
          :current-page="page"
          hide-on-single-page
        >
        </el-pagination>
      </div>
      <div slot="footer">
        <el-button @click="rollCall_visible = false">关闭</el-button>
        <!-- <el-button type="primary" :loading="loading">关闭
        </el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ComCarousel from "@/components/com/comCarousel";
var dayjs = require("dayjs");
import { carousel } from "@/api/common.js";

import { course_list, testListapi } from "@/api/course.js";
import { list_params, question_list } from "@/api/problemList.js";
import { options_list, message, sysNotice } from "@/api/me.js";
import { matchList, options } from "@/api/match.js";

import CourseItem from "@/components/CourseItem/CourseItem.vue";
import courseList from "@/components/courseList/courseList.vue";
import workList from "@/components/workList/workList.vue";
import ExamList from "@/components/ExamList/ExamList.vue";
import testList from "@/components/testList/testList.vue";
import ProblemItem from "@/components/ProblemItem/ProblemItem.vue";
import Context from "@/components/Context/Context.vue";
import Forum from "@/components/Forum/Forum.vue";
import Team from "@/components/Team/Team.vue";
import Banner from "@/components/banner/banner.vue";
import {
  activity_list,
  sign,
  sign_list,
  sign_del,
  sign_edit,
  sign_info,
} from "@/api/activity";
import $ from "jquery";
export default {
  name: "Index",
  components: {
    ComCarousel,
    CourseItem,
    ProblemItem,
    Context,
    Forum,
    Team,
    Banner,
    courseList,
    workList,
    ExamList,
    testList,
  },
  data() {
    return {
      dayjs,
      total: 0,
      sign_rules: {
        realname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        school_name: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        educate_id: [
          { required: true, message: "请输入教育ID", trigger: "blur" },
        ],
        grade_name: [
          { required: true, message: "请输入年级", trigger: "blur" },
        ],
        class_name: [
          { required: true, message: "请输入班级", trigger: "blur" },
        ],
        id_card: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱号", trigger: "blur" }],
      },
      sign_form: {
        realname: "",
        school_name: "",
        grade_name: "",
        class_name: "",
        id_card: "",
        mobile: "",
        email: "",
        educate_id: "",
      },
      activity_id: "",
      rollCall_list: [],
      loading: false,
      rollCall_visible: false,
      apply_visible: false,
      switchPosition: true,
      courseLoading: false,
      workLoading: false,
      problemLoading: false,
      subjectLoading: false,
      tableLoading: false,
      scroll: 0,
      indexi: 0,
      positionList: ["课程", "题单", "题目", "比赛·考试·作业", "团队", "论坛"],
      matchList: [],
      ExamList: [], //比赛考试
      matchOptionList: [],
      courseList: [],
      searchValue: "",
      page: 1,
      page_size: 6,
      keyword: "",
      type: "",
      cate: "",
      problemListData: [
        {
          color: "#2D93F8",
          title: "NOIP真题",
          src: require("../assets/index/index (12).png"),
          list: [],
          value: "",
        },
        {
          color: "#FD7E00",
          title: "知识点题单",
          src: require("../assets/index/index (13).png"),
          list: [],
          value: "",
        },
        {
          color: "#108581",
          title: "模拟测试",
          src: require("../assets/index/index (14).png"),
          list: [],
          value: "",
        },
      ],
      optionsList: [{ list: [] }, { list: [] }],
      // 团队下的比赛、作业、考试 默认显示比赛
      tother: 2,

      // 轮播
      courseCarousel: [],
      matchCarousel: [],
      forumCarousel: [],
      indexCarousel: [],
      activity_list: [{ details: "", id: "" }],
      is_edit: false,
      listData: [],
      testList: [], //试题数据
    };
  },
  created() {
    //获取系统消息
    this.getSysMessage();
    // this.getindexBanner();
    // 轮播
    // this.getIndexCarousel();
    //  课程列表
    this.getCourseList();
    // 试题列表
    this.getTestList();
    // this.getListParams();
    // this.getOptionsList();
    // 比赛考试筛选
    this.getOptions();
    this.getExamList();
    // this.geTotherList();
    //获取活动列表
    // activity_list({}).then(({ data }) => {
    //   this.activity_list = data.list.length
    //     ? data.list
    //     : [{ details: "", id: "" }];
    // });
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.5,
        singleHeight: 18,
        // limitMoveNum: 2,
        // step: 1, // 数值越大速度滚动越快
        // limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 去系统公告详情
    goDetails(id) {
      this.$router.push({ path: "/forum/forumSee", query: { id: id } });
    },
    // 获取系统消息
    getSysMessage() {
      sysNotice().then(({ data }) => {
        console.log(data, "message~~~~~~~~~");
        this.listData = data.data;
      });
    },
    // 获取试题列表
    getTestList() {
      this.tableLoading = true;
      testListapi({ page: 1, page_size: 18, from_id: 1 })
        // question_list({ page: 1, page_size: 18, from_id: 1 })
        .then(({ data }) => {
          console.log(data, "获取试题列表");
          // this.testListList = data.data;
          this.tableLoading = false;
          const groupedData = [];
          for (let i = 0; i < data.data.length; i += 3) {
            const group = data.data.slice(i, i + 3);
            groupedData.push(group);
          }
          const mergedData = groupedData.map((group) => ({
            id1: group[0] ? group[0].id : "",
            list_id1: group[0] ? group[0].list_id : "",
            course_id1: group[0] ? group[0].course_id : "",
            title1: group[0] ? group[0].title : "",

            id2: group[1] ? group[1].id : "",
            list_id2: group[1] ? group[1].list_id : "",
            course_id2: group[1] ? group[1].course_id : "",
            title2: group[1] ? group[1].title : "",

            id3: group[2] ? group[2].id : "",
            list_id3: group[2] ? group[2].list_id : "",
            course_id3: group[2] ? group[2].course_id : "",
            title3: group[2] ? group[2].title : "",
          }));
          // const mergedData = data.data.reduce((acc, curr, index) => {
          //   if (index % 3 === 0) {
          //     const nextObj1 = data.data[index + 1];
          //     const nextObj2 = data.data[index + 2];
          //     if (nextObj1 && nextObj2) {
          //       acc.push({
          //         id1: curr.id,
          //         title1: curr.title,
          //         id2: nextObj1.id,
          //         title2: nextObj1.title,
          //         id3: nextObj2.id,
          //         title3: nextObj2.title,
          //       });
          //     }
          //   }
          //   return acc;
          // }, []);
          this.testList = mergedData;
          console.log(this.testList, "testListtestList");
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    open_sign_info() {
      sign_info({ activity_id: this.activity_id }).then(({ data }) => {
        for (let j in data.info) {
          for (let v in this.sign_form) {
            if (j == v) {
              this.sign_form[j] = data.info[j];
            }
          }
        }
        this.is_edit = true;
      });
    },
    cancel() {
      this.$confirm("确定要取消报名吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sign_del({ activity_id: this.activity_id }).then((res) => {
          this.apply_visible = false;
          this.$message.success(res.msg);
        });
      });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.open_sign_list();
    },
    open_sign_list() {
      sign_list({ activity_id: this.activity_id, page: this.page }).then(
        ({ data }) => {
          this.rollCall_list = data.list;
          this.total = data.count;
        }
      );
    },
    apply_closed() {
      this.is_edit = false;
      this.$refs["sign_form"].resetFields();
    },
    rollCall(id) {
      this.activity_id = id;
      this.rollCall_visible = true;
    },
    apply(id, sign_end_time) {
      if (!localStorage.getItem("user_token")) {
        return (this.$store.state.lodinShow = true);
      }

      if (sign_end_time < Date.parse(new Date())) {
        return this.$message.error("活动已结束");
      }
      this.activity_id = id;
      this.apply_visible = true;
    },
    sign_save() {
      this.$refs["sign_form"].validate((valid) => {
        if (valid) {
          // let { mobile, id_card } = this.sign_form
          // var mobile_pattern = /(13\d|14[579]|15[^4\D]|17[^49\D]|18\d)\d{8}/g
          // let id_card_pattern = /\d{17}[0-9Xx]|\d{15}/g
          // if (mobile_pattern.test(mobile)) {
          //   this.$message.error('请输入正确的手机号')
          //   return
          // }
          // if (id_card_pattern.test(id_card)) {
          //   this.$message.error('请输入正确的身份证号')
          //   return
          // }
          if (this.is_edit) {
            sign_edit({
              ...this.sign_form,
              activity_id: this.activity_id,
            }).then((res) => {
              this.apply_visible = false;
              this.$message.success(res.msg);
            });
            return;
          }
          sign({ ...this.sign_form, activity_id: this.activity_id }).then(
            (res) => {
              this.apply_visible = false;
              this.$message.success(res.msg);
            }
          );
        }
      });
    },
    getindexBanner() {
      if (!this.$store.state.indexImg.length) {
        carousel({ type: 2 }).then(({ data }) => {
          this.indexCarousel = data.list;
          this.$store.state.indexImg = data.list;
        });
      } else {
        this.indexCarousel = this.$store.state.indexImg;
      }
    },
    getIndexCarousel() {
      carousel({ type: 9 })
        .then(({ data }) => {
          this.courseCarousel = data.list;
        })
        .catch((err) => {});
      carousel({ type: 14 })
        .then(({ data }) => {
          this.matchCarousel = data.list;
        })
        .catch((err) => {});
      carousel({ type: 10 })
        .then(({ data }) => {
          this.forumCarousel = data.list;
        })
        .catch((err) => {});
    },

    backTop() {
      this.$jquery(this.$el).parent().scrollTop(0);
    },
    clickPosition(i) {
      try {
        document.getElementById("contentBox" + i).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (e) {
        var top =
          this.$jquery(this.$el)
            .children(".content:eq(0)")
            .children("div:eq(" + i + ")")
            .offset().top - 100;
        this.$jquery(this.$el)
          .parent()
          .scrollTop(this.scroll + top);
      }
    },
    // 滚动
    handleScroll(event) {
      var target = (event || {}).target;
      if (!target && this.$el) {
        target = this.$jquery(this.$el).parent().get(0);
      }
      var maxWidth = 0;
      this.$jquery(target)
        .children()
        .each(function () {
          if ($(this).width() > maxWidth) {
            maxWidth = $(this).width();
          }
        });
      var right = this.$jquery(target).outerWidth() - maxWidth;
      var height = this.$jquery(this.$el).height();
      var $position = this.$jquery(this.$el).children(".position:eq(0)");
      var minWidth =
        parseInt($position.children(".box:eq(0)").css("min-width") || 0) ||
        $position.children(".box:eq(0)").innerWidth() - 20;
      this.scroll = this.$jquery(target).scrollTop();
      var top = this.$jquery(this.$el).children(".content:eq(0)").offset().top;
      var bottom = Math.max(
        60,
        this.scroll + (window.screen.height || 0) - height - 60
      );
      var pheight = $position.height() + 60;
      if (window.screen.height - top >= pheight) {
        $position.css("bottom", bottom + "px");
        $position.css("top", "unset");
      } else {
        $position.css("top", top + "px");
        $position.css("bottom", "unset");
      }
      $position.css("right", right + "px");
      $position.children(".box:eq(0)").css("min-width", minWidth + "px");
      // console.log("handleScroll"  , window.screen.height , top, pheight);
      let childrens = this.$jquery(this.$el)
        .children(".content:eq(0)")
        .children();
      // let $height = this.$jquery(this.$el).children('.content:eq(0)').height();
      let $index = 0;
      for (let i = 0; i < childrens.length; i++) {
        var $this = this.$jquery(childrens[i]);
        if ($this.height() + $this.offset().top <= window.screen.height) {
          $index = i;
          // break;
        }
      }
      this.indexi = $index;
    },
    // 获取课程
    getCourseList() {
      this.courseLoading = true;
      course_list({
        page: this.page,
        page_size: 6,
        keyword: this.keyword,
        type: this.type,
        cate: this.cate,
        recommend: -1,
      })
        .then((res) => {
          this.courseLoading = false;

          this.courseList = (res.data || {}).data;
          console.log(this.courseList, "this.courseList this.courseList ");
        })
        .finally(() => {
          this.courseLoading = false;
        });
    },
    // 获取题单筛选
    getListParams() {
      this.problemLoading = true;
      list_params({})
        .then((res) => {
          let arry = res.data.from_id.list.splice(0, 3);
          this.problemListData.map((v, i) => {
            v.title = arry[i].label;
            v.value = arry[i].value;
          });
          arry.forEach((item, index) => {
            question_list({
              page: 1,
              page_size: 5,
              from_id: item.value,
              recommend: -1,
            })
              .then((res) => {
                this.problemListData[index].list = (res.data || {}).data;
              })
              .finally(() => {
                this.problemLoading = false;
              });
          });
        })
        .catch((error) => {});
    },
    // 获取题目分类列表
    getOptionsList() {
      this.subjectLoading = true;
      let list = [1, -1];
      list.forEach((item, index) => {
        options_list({ page: 1, page_size: 6, type: item, recommend: -1 })
          .then((res) => {
            this.optionsList[index].list = (res.data || {}).data;
          })
          .finally(() => {
            this.subjectLoading = false;
          });
      });
    },
    // 比赛、作业、考试
    getOptions() {
      this.tableLoading = true;
      options({})
        .then(({ data }) => {
          this.matchOptionList = data.type.list;
          this.getMatchList(data.type.list[0].value);
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 作业
    getMatchList() {
      this.workLoading = true;
      matchList({ page: 1, page_size: 6, type: 3, recommend: -1 })
        .then(({ data }) => {
          this.matchList = data.data;
          this.workLoading = false;
        })
        .catch((err) => {
          this.workLoading = false;
        });
    },
    // 比赛考试
    getExamList() {
      this.tableLoading = true;
      matchList({ page: 1, page_size: 6, type: "1,2", recommend: -1 })
        .then(({ data }) => {
          this.ExamList = data.data;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },

    // 题目详情
    clickOption(item) {
      if (!localStorage.getItem("user_token")) {
        return (this.$store.state.lodinShow = true);
      }
      if (this.userType == 0) {
        this.$store.state.showBinding = true;
        return;
      }
      this.$handleRoute({ id: item.id }, "QuestionInfo");
    },
  },
  mounted() {
    this.handleScroll();
    this.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.addEventListener("scroll", this.handleScroll, null, true);
  },
};
</script>
<style lang="less" scoped>
.sysMessage {
  display: flex;
  padding: 20px;
  // height: 60px;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  .title {
    width: 73px;
    // height: 18px;
    font-size: 14px;
    font-weight: bold;
    color: #00957E;

    font-style: italic;
    // line-height: 18px;
    margin-left: 10px;
  }
}
.noticItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li {
  list-style-type: none;
}
.pagination {
  margin: 1rem 0 0;
  display: flex;
  justify-content: center;
}
.user_list {
  margin-top: 10px;
  text-align: center;
  .rollCall {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
.carousel-item {
  cursor: pointer;
  position: relative;
  .activity {
    position: absolute;
    z-index: 99;
    width: 400px;
    height: 410px;
    left: 120px;
    top: 70px;
    .box-card {
      width: 100%;
      height: 100%;
    }
    .activity_item {
      width: 100%;
      // height: 200px;
      .image {
        width: 100%;
        height: 145px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .activity_time {
        font-size: 12px;
        color: #999;
        margin-top: 6px;
      }
      .details {
        color: #999;
        font-size: 12px;
        margin-top: 6px;
        height: 32px;
      }
      .apply {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
      }
    }
  }
}
.noData {
  position: absolute;
  top: 45%;
  left: 45%;
  color: #999;
}

.position {
  position: fixed;
  text-align: center;
  z-index: 2;
  .switch {
    position: absolute;
    top: 47%;
    font-size: 28px;
    color: #00957e;
    left: 0;
    cursor: pointer;
  }
  .backTop {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00957e;
    }
  }
  .ul {
    margin-bottom: 8px;
    .indexBgc {
      a {
        color: #fff !important;
        background-color: #00957e;
      }
    }
    .li {
      text-align: center;

      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      cursor: pointer;
      overflow: hidden;
      margin: 0.4rem 0;
      a:hover {
        color: #fff !important;
        background-color: #00957e;
      }
      a {
        display: inline-block;
        // border-radius: 10px 10px 0 0;
        border-radius: 4px;
        // height: 100%;
        // width: 100%;
        padding: 5px 15px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}
// .index {
//   // background-color: #fff;
// }
.course {
  // margin-top: 3rem;
  .img > img {
    width: 100%;
    height: 320px;
  }
  .Course-item {
    margin-bottom: 1.8rem;
  }
}

.Problem {
  .item {
    // width: 640px;
    height: 350px;
    box-sizing: border-box;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    border-radius: 20px 20px 0px 0px;
    position: relative;
    .t {
      padding-left: 24px;
      font-size: 24px;

      color: #fff;
      height: 41px;
      line-height: 41px;
      background: linear-gradient(360deg, #fd7e00 0%, #f26b0c 100%);
      border-radius: 20px 20px 0px 0px;
    }
    .con {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-left: 81px;
      // padding-right: 117px;
      padding: 0 5rem;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      .el-button {
        padding: 0;
        text-align: center;
        width: 50px;
        height: 23px;
        background: #2d93f8;
        border: none;
        border-radius: 2px;
      }
    }
    span {
      color: #353535;
    }
    .ProblemList-name {
      width: 132px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.top {
  margin: 2rem 0;
  text-align: center;
  h1 {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2e2e2e;
    margin: 0;
  }
  span {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00957e;
  }
}
.image {
  width: 170px;
  height: 290px;
  img {
    width: 170px;
    height: 290px;
  }
}
.el-carousel {
  img {
    width: 100%;
    // height: 600px;
  }
}
.seamless-warp {
  width: 100vw;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .items {
    display: flex;
    width: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    .contenttitle {
      margin-left: 6px;
      height: 18px;
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      cursor: pointer;
    }
  }
}
</style>
