<template>
  <div class="main team">
    <div class="top">
      <h1>团队</h1>
      <span>Team</span>
      <div class="image">
        <img src="../../assets/index/index (6).png" alt />
        <img src="../../assets/index/index (7).png" alt />
      </div>
    </div>
    <div class="team-content" v-loading="teamLoading">
      <!-- <el-carousel :interval="4000" type="card" height="338px">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div @click="listItem(item)">
            <img :src="item.cover" alt />
          </div>
        </el-carousel-item>
      </el-carousel> -->

      <div class="table" v-loading="teamLoading">
        <el-table
          ref="multipleTable"
          :data="list"
          tooltip-effect="dark"
          style="width: 100%"
          @row-click='rowDblclick'
          :row-key="(row) => row.id"
        >
          <el-table-column label="团队名称" prop="name" align="center" minWidth="200" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
          <span class="ProblemList-name">{{ scope.row.name }}</span></template
        > -->
            <!-- <template slot-scope="scope" >
              <el-tooltip
                effect="dark"
                :content="scope.row.name"
                placement="top"
                v-if="scope.row.name.length > 30"
              >
                <span class="ProblemList-name">{{
                  scope.row.name.substring(0, 30) + "..."
                }}</span>
              </el-tooltip>
              <span class="ProblemList-name" v-else>
                {{ scope.row.name }}
              </span>
            </template> -->
          </el-table-column>
          <el-table-column label="创建人" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.user_realname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="团队人数" minWidth="100" align="center">
            <template slot-scope="scope"
              >{{ scope.row.member_count }}人</template
            >
          </el-table-column>
          <el-table-column label="标签" minWidth="100" align="center">
            <template slot-scope="scope">{{
              scope.row.type_name ? scope.row.type_name : "--"
            }}</template>
          </el-table-column>
          <el-table-column label="创建时间" minWidth="100" align="center">
            <template slot-scope="scope">{{
              scope.row.create_time_format
            }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="margin-top: 20px;">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="currentChange"
                :page-size="pageSize"
                :current-page="page"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
    </div>
  </div>
</template>

<script>
// import { recommendTeam } from "@/api/team.js";
import { teamList } from "@/api/team.js";

export default {
  name: "course",
  data() {
    return {
      teamLoading: false,
      page: 1,
      pageSize: 10,
      list: [],
      total:0
    };
  },
  components: {},
  created() {
    this.getTeamList()
    // this.teamLoading = true;
    // recommendTeam({ num: 5, recommend: -1 })
    //   .then(({ data }) => {
    //     this.list = data;
    //     this.teamLoading = false;

    //     console.log(data, "推荐团队成功");
    //   })
    //   .catch((err) => {
    //     console.log(err, "推荐团队失败");
    //     this.teamLoading = false;
    //   });
  },
  methods: {
     // 团队列表
     getTeamList() {
      this.teamLoading=true
      teamList({
        page: this.page,
        page_size: this.pageSize,
      })
        .then(({ data }) => {
          this.teamLoading=false

          this.list = data.data;
          this.total = data.total;
        })
        .catch((err) => {
      this.teamLoading=false
        });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getTeamList();
    },
     // 点击详情
     rowDblclick (row) {
      console.log(row,"rwsssssss");
      if (this.userInfo.id && !this.userInfo.type) {
        this.$store.state.showBinding = true
      }else{
        this.$handleRoute({team_id:row.id},'TeamInfo')
      }
    },
    listItem(item) {
      if (!localStorage.getItem("user_token")) {
        return (this.$store.state.lodinShow = true);
      }
      if (this.userType == 0) {
        this.$store.state.showBinding = true;
        return;
      }
      this.$handleRoute({ team_id: item.id }, "TeamInfo");
    },
    handleClick() {},
  },
};
</script>

<style lang="less" scoped>
.table {
      ::v-deep {
        .el-table thead {
          color: #000;
        }
      }
      
    }
.team {
  height: auto;
  padding-top: 50px;

  .top {
    text-align: center;
    h1 {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2e2e2e;
      margin: 0;
    }
    span {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #00957e;
    }
    .image {
      margin-top: 5px;
      margin-bottom: 40px;
      img {
        width: 55px;
        height: 55px;
      }
    }
  }
  .team-content {
    text-align: center;
    img {
      width: 100%;
      height: 100%;
      // width: 612px;
      // height: 338px;
    }
  }
  ::v-deep {
    .el-carousel__item {
      border-radius: 20px;
    }
  }
}
</style>
