<!--  -->
<template>
  <div class="">
    <el-table
      :data="courseData"
      tooltip-effect="dark"
      style="width: 100%"
      @row-click="rowDblclick"
      :row-key="(row) => row.id"
      :row-style="{height: '0'}"
    :cell-style="{padding: '6px',cursor:'pointer'}"
    :header-cell-style="{ height: '50',padding: '4px', background: '#F9F9F9' }"
    >
      <el-table-column
        label="课程名称"
        prop="course_name"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="开班时间" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.course_start_date }}</span>
        </template>
      </el-table-column>
      <el-table-column label="课时" width="80"  align="center">
        <template slot-scope="scope">{{ scope.row.class_hour }}</template>
      </el-table-column>
      <el-table-column label="地点"  align="center" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.course_address}}</template>
      </el-table-column>
      <el-table-column label="类型" width="100" align="center">
        <template slot-scope="scope">{{
          scope.row.cid_name
        }}</template>
      </el-table-column>
      <el-table-column label="授课老师" width="140" align="center">
        <template slot-scope="scope">{{
          scope.row.teach_name
        }}</template>
      </el-table-column>
      <el-table-column label="价格" width="100" align="center">
        <template slot-scope="scope">{{
          scope.row.price?'￥'+ scope.row.price:'免费'
        }}</template>
      </el-table-column>
      <el-table-column label="学习人数" width="100" align="center">
        <template slot-scope="scope">{{
          scope.row.buy_num
        }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    courseData: {
      type: Array,
      default: () => [],
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //方法集合
  methods: {
    rowDblclick(row) {
      // console.log(row,"row~~~~~~~")
      this.$handleRoute({ course_id: row.id }, 'courseDetail')
      console.log("课程跳转");
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
</style>