import request from "@/Utils/api"

// 当前活动列表
export function activity_list (data) {
  return request({
    url: "api/v1_0_0.sign_activity/_list",
    method: "post",
    data,
  })
}
// 报名
export function sign (data) {
  return request({
    url: "api/v1_0_0.sign_activity/sign",
    method: "post",
    data,
  })
}
export function sign_list (data) {
  return request({
    url: "api/v1_0_0.sign_activity/sign_list",
    method: "post",
    data,
  })
}
// 取消报名
export function sign_del (data) {
  return request({
    url: "api/v1_0_0.sign_activity/sign_del",
    method: "post",
    data,
  })
}
// 编辑报名
export function sign_edit (data) {
  return request({
    url: "api/v1_0_0.sign_activity/sign_edit",
    method: "post",
    data,
  })
}

export function sign_info (data) {
  return request({
    url: "api/v1_0_0.sign_activity/sign_info",
    method: "post",
    data,
  })
}
