<!--  -->
<template>
  <div class="">
    <el-table
      :data="workData"
      tooltip-effect="dark"
      style="width: 100%"
      @row-click="rowDblclick"
      :row-key="(row) => row.id"
      :row-style="{ height: '0' }"
      :cell-style="{padding: '6px',cursor:'pointer'}"
      :header-cell-style="{
        height: '50',
        padding: '4px',
        background: '#F9F9F9',
      }"
    >
      <el-table-column
        label="状态"
        width="120"
        prop="activ_status"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div
            class="state"
            style="color: #28b58c"
            v-if="scope.row.activ_status == 1"
          >
            正在进行
          </div>
          <div
            class="state"
            style="color: #f15153"
            v-if="scope.row.activ_status == -2"
          >
            已结束
          </div>
          <div
            class="state"
            style="color: #909399"
            v-if="scope.row.activ_status == -1"
          >
            未开始
          </div>
        </template>
      </el-table-column>
      <el-table-column label="名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="题目数" width="80" align="center">
        <template slot-scope="scope">{{ scope.row.question_num }}</template>
      </el-table-column>
      <el-table-column label="时间" width="120" align="center">
        <template slot-scope="scope">{{scope.row.publish_date? scope.row.publish_date:'--' }}</template>
      </el-table-column>
      <el-table-column label="可见范围" show-overflow-tooltip align="center">
        <template slot-scope="scope">{{ scope.row.is_open_str }}</template>
      </el-table-column>
      <el-table-column label="举办者" width="140" align="center">
        <template slot-scope="scope">{{ scope.row.group_name }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {is_cat } from "@/api/match.js"

export default {
  props: {
    workData: {
      type: Array,
      default: () => [],
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //方法集合
  methods: {
    rowDblclick(e) {
      console.log(e,'e')
      if (!localStorage.getItem("user_token")) {
        return this.$store.state.lodinShow = true
      }
      if (!this.userType) {
        this.$store.state.showBinding = true
        return
      }
      is_cat({ id: e.id }).then(res => {
        this.$router.push({ name: "matchDetail"+e.type, query: { id: e.id} })
      })
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
</style>